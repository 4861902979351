/* You can add global styles to this file, and also import other style files */
.min-width {
  width: 0.1%;
}

.cursor:hover {
  cursor: pointer;
}

/* inicio ajuste no menu da suite-rfb */
.menu .active > .raiz::after {
  transform: rotate(0deg);
}

.menu .active > a[aria-expanded="true"].menu-item.raiz::after {
  transform: rotate(180deg);
}
/* final do ajuste */

/* título dos menus de atendimento */
app-menu-feature-actions .title {
  color: darkblue;
  font-weight: bold;
}

/* dynamic-modal */
.modal-content {
  width: fit-content;
}

/* tamanho dos modais do menu */
.via-modal-lg {
  min-width: 1330px;
}

.via-modal-md {
  min-width: 800px;
}

.link {
  cursor: pointer;
  color: #337ab7;
}

.link:hover {
  text-decoration: underline;
}

